@tailwind base;
@tailwind components;
@tailwind utilities;

.backgroundImage {
  max-height: 500px;
  position: relative;
  width: 100%;
}

.quill .ql-editor {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.ql-bubble .ql-editor p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: default;
}

.ql-editor .ql-editor-comments {
  min-height: 600px !important;
  width: 100%;
}

.ql-editor-blogs {
  max-height: 100px !important;
  height: 100px !important;
  width: 100%;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
}

.ql-editor .ql-editor-blogs {
  padding: 0 !important;
  cursor: pointer !important;
}

.ql-blank {
  min-height: 30em ;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em; 
}

.ql-toolbar {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

pre {
  font-family: MyFancyCustomFont, monospace;
  font-size: inherit;
  word-wrap: break-word !important;
  border-radius: 0.2rem;
  overflow-x: auto;
  background: #030f1c;
  color: whitesmoke;
  padding: 15px !important;
  white-space: pre-wrap;
}

blockquote {
  border-left: 5px solid #1363df;
  margin: 1em 0 !important;
  background: #ededed;
  font-style: italic;
  position: relative;
  padding: 1.2em 30px 1.2em 45px !important;
}

blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: #1363df;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

blockquote::after {
  content: "";
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0284c7;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-top: 100px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
